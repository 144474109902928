/* root.css */
:root {
  --background-color: 255, 250, 244;
  --primary-color: 33, 33, 33; 
  --secondary-color: 243, 223, 200;
  --third-color: rgb(214, 240, 185); 
  --fourth-color: 249, 118, 103; 
  --text-color: #fff; 
  --link-hover-color: #555;
  --navbar-height: 30rem; 
  
  @import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

}

h1{
  font-family: 'Jost', sans-serif;
  font-size: 5rem;
  color: var(--fourth-color);
  text-align: center;
  position: absolute;
  top: 0;
  margin-top: -1rem;
}

h2{
  font-family: 'Jost', sans-serif;
  font-size: 4rem; /* Adjust font size */
  font-weight: 500;
  font-style: normal;
  text-align: left;
  text-justify: inter-word;
  color: var(--primary-color);
  top: 0;
}

a{
  font-family: 'Jost', sans-serif;
  padding: .5rem;
  margin-left: .25rem;
  text-align: left;
  font-optical-sizing: auto;
  text-transform: uppercase;
}

body{
  background-color: var(--background-color);
}

/* 
Adorn Garland Regular
font-family: "adorn-garland", sans-serif;
font-weight: 400;
font-style: normal;
Adorn Pomander Regular
font-family: "adorn-pomander", sans-serif;
font-weight: 400;
font-style: normal;
Adorn Bouquet Regular
font-family: "adorn-bouquet", sans-serif;
font-weight: 400;
font-style: normal;
Adorn Coronet Regular
font-family: "adorn-coronet", sans-serif;
font-weight: 400;
font-style: normal; */
